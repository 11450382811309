import { toast } from "react-toastify";
import { post } from "services";
import {
    TOGGLE_LOADING,
    RESET_NFT_TYPES,
    LIST_ALL_NFT_TYPES,
    UPDATE_TYPE_VISIBILITY,
    LIST_NFT_TYPE_DETAILS,
    UPDATE_NFT_TYPE_NAME_FIELD,
} from "../types";
import {
    FETCH_ALL_NFT_TYPES,
    CREATE_NEW_NFT_TYPE,
    TOGGLE_TYPE_VISIBILITY,
    FETCH_NFT_TYPE_DETAILS,
    UPDATE_NFT_TYPES,
    UPDATE_NFT_NAME_FIELD,
} from "graphql";

export const listAllNftTypes = () => {
    return async (dispatch, getState) => {
        dispatch({ type: TOGGLE_LOADING, payload: true });

        const { pageSize, pageNumber, list } = getState().nftTypes;

        let apiResponse = await post("", {
            query: FETCH_ALL_NFT_TYPES,
            variables: {
                pageSize: pageSize,
                pageNumber: pageNumber,
            },
        });

        if (apiResponse.data.customStatus) {
            let {
                list_all_nft_types: { data, hasMore },
            } = apiResponse.data.data;

            let updatedList = [...list, ...data];

            dispatch({
                type: LIST_ALL_NFT_TYPES,
                payload: {
                    list: updatedList,
                    showViewMore: hasMore,
                    pageNumber: pageNumber + 1,
                },
            });
        }
        dispatch({ type: TOGGLE_LOADING, payload: false });
    };
};

export const createNewNftType = (requestData) => {
    return async (dispatch, getState) => {
        dispatch({ type: TOGGLE_LOADING, payload: true });

        let apiResponse = await post("", {
            query: CREATE_NEW_NFT_TYPE,
            variables: requestData,
        });

        if (apiResponse.data.customStatus) {
            toast.success("NFT Type Created");
            dispatch(resetNftTypesData());
            dispatch(listAllNftTypes());
        }

        dispatch({ type: TOGGLE_LOADING, payload: false });
    };
};

export const resetNftTypesData = () => {
    return async (dispatch, getState) => {
        dispatch({ type: RESET_NFT_TYPES });
    };
};

export const toggleNftTypeVisibility = (requestData) => {
    return async (dispatch, getState) => {
        dispatch({ type: TOGGLE_LOADING, payload: true });

        let apiResponse = await post("", {
            query: TOGGLE_TYPE_VISIBILITY,
            variables: requestData,
        });

        if (apiResponse.data.customStatus) {
            dispatch({ type: UPDATE_TYPE_VISIBILITY, payload: requestData.id });
            toast.success("Type updated");
        }

        dispatch({ type: TOGGLE_LOADING, payload: false });
    };
};

export const listNftTypeDetails = (requestData) => {
    return async (dispatch, getState) => {
        dispatch({ type: TOGGLE_LOADING, payload: true });

        let apiResponse = await post("", {
            query: FETCH_NFT_TYPE_DETAILS,
            variables: requestData,
        });

        if (apiResponse.data.customStatus) {
            let {
                list_nft_type_details: { data },
            } = apiResponse.data.data;

            dispatch({
                type: LIST_NFT_TYPE_DETAILS,
                payload: data,
            });
        }
        dispatch({ type: TOGGLE_LOADING, payload: false });
    };
};

export const updateNftType = (requestData) => {
    return async (dispatch, getState) => {
        dispatch({ type: TOGGLE_LOADING, payload: true });

        let apiResponse = await post("", {
            query: UPDATE_NFT_TYPES,
            variables: requestData,
        });

        if (apiResponse.data.customStatus) {
            toast.success("Fields Added");
        }

        dispatch({ type: TOGGLE_LOADING, payload: false });
    };
};

export const updateNftNameField = (requestData) => {
    return async (dispatch) => {
        dispatch({ type: TOGGLE_LOADING, payload: true });

        let apiResponse = await post("", {
            query: UPDATE_NFT_NAME_FIELD,
            variables: requestData,
        });

        if (apiResponse.data.customStatus) {
            dispatch({ type: UPDATE_NFT_TYPE_NAME_FIELD, payload: requestData.fieldId });
        }

        dispatch({ type: TOGGLE_LOADING, payload: false });
    };
};
