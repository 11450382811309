import {
    LIST_ALL_NFT_TYPES,
    RESET_NFT_TYPES,
    UPDATE_TYPE_VISIBILITY,
    LIST_NFT_TYPE_DETAILS,
    UPDATE_NFT_TYPE_NAME_FIELD,
} from "../types";

const INITIAL_STATE = {
    list: [],
    showViewMore: false,
    pageNumber: 1,
    pageSize: 10,
    details: {},
};

export default (state = INITIAL_STATE, { type, payload }) => {
    switch (type) {
        case LIST_ALL_NFT_TYPES:
            return {
                ...state,
                pageSize: 10,
                ...payload,
            };

        case RESET_NFT_TYPES:
            return INITIAL_STATE;

        case UPDATE_TYPE_VISIBILITY:
            state.list.map((e) => {
                if (e._id == payload) {
                    e.is_visible = !e.is_visible;
                }
            });
            return { ...state };

        case LIST_NFT_TYPE_DETAILS:
            return {
                ...state,
                details: payload,
            };

        case UPDATE_NFT_TYPE_NAME_FIELD:
            const oldField = state.details?.fields.filter((e) => e.is_nft_name);
            const oldFieldId = oldField[0]._id;
            state.details?.fields.map((e) => {
                if (e._id == oldFieldId) {
                    e.is_nft_name = false;
                }
                if (e._id == payload) {
                    e.is_nft_name = true;
                }
            });
            return { ...state };
        default:
            return state;
    }
};
