import { Connection, actions } from "@metaplex/js";
import { toast } from "react-toastify";
import { store } from "store/store";
import { toggleSidebar, apiCall, updateNftData, resetNftsData, listAllNfts } from "store/actions";
import { FILE_UPLOAD } from "graphql";
import { SOL_LEDGER_ACCOUNT, SOLANA_CLUSTER, getMetadata } from "helpers";
import { toggleLoading } from "store/actions";

const checkForPhantomApp = () => {
    if ("solana" in window) {
        const provider = window.solana;
        if (provider.isPhantom) {
            return true;
        } else {
            return false;
        }
    }
};

export const handleSingleNftCreation = async ({ value, walletId }) => {
    store.dispatch(toggleLoading(true));

    if (walletId === "All") {
        store.dispatch(toggleLoading(false));
        return toast.info("Please select one wallet address");
    }

    let isPhantomAvailable = checkForPhantomApp();
    if (!isPhantomAvailable) {
        store.dispatch(toggleLoading(false));
        return toast.warning("Need to install phantom extension");
    }

    try {
        let metadata = getMetadata({
            name: value.name,
            description: value.description,
            royaltyPercentage: value.royalty_percentage * 100,
            // imageUrl: imageUrl,
            imageUrl: value.art_url,
            // imageType: `image/${base64img.split(";")[0].split("/")[1]}`,
            imageType: "image/jpeg",
            category: "image",
            creators: [{ address: walletId, share: 100 }],
        });

        let objJsonStr = JSON.stringify(metadata);
        let objJsonB64 = Buffer.from(objJsonStr).toString("base64");

        let metadataUpload = await apiCall(FILE_UPLOAD, { file: objJsonB64, fileType: "json" });
        let metadataUrl = metadataUpload.data?.upload_file?.url;

        const connection = new Connection(SOLANA_CLUSTER);
        const resp = await window.solana.connect();

        const NftMint = await actions.mintNFT({
            connection: connection,
            wallet: window.solana,
            uri: metadataUrl,
            maxSupply: 1,
        });

        store.dispatch(updateNftData({ id: value._id, nftId: NftMint.mint.toString() }));
        store.dispatch(toggleLoading(false));
    } catch (err) {
        store.dispatch(toggleLoading(false));
        toast.error(err.message);
    }
};

export const handleBulkNftCreation = async ({ data, walletId }) => {
    store.dispatch(toggleLoading(true));

    if (walletId === "All") {
        store.dispatch(toggleLoading(false));
        return toast.info("Please select one wallet address");
    }

    let isPhantomAvailable = checkForPhantomApp();
    if (!isPhantomAvailable) {
        store.dispatch(toggleLoading(false));
        return toast.info("Need to install phantom extension");
    }

    let nftsData = data.filter((e) => e.status === "pending");
    if (nftsData.length) {
        for (let e in nftsData) {
            try {
                let metadata = getMetadata({
                    name: nftsData[e].name,
                    description: nftsData[e].description,
                    royaltyPercentage: nftsData[e].royalty_percentage * 100,
                    // imageUrl: imageUrl,
                    imageUrl: nftsData[e].art_url,
                    // imageType: `image/${base64img.split(";")[0].split("/")[1]}`,
                    imageType: "image/jpeg",
                    category: "image",
                    creators: [{ address: walletId, share: 100 }],
                });

                let objJsonStr = JSON.stringify(metadata);
                let objJsonB64 = Buffer.from(objJsonStr).toString("base64");

                let metadataUpload = await apiCall(FILE_UPLOAD, { file: objJsonB64, fileType: "json" });

                if (!metadataUpload.status) {
                    store.dispatch(toggleLoading(false));
                    break;
                }

                let metadataUrl = metadataUpload.data?.upload_file?.url;
                store.dispatch(toggleLoading(true));

                const connection = new Connection(SOLANA_CLUSTER);
                const resp = await window.solana.connect();

                const NftMint = await actions.mintNFT({
                    connection: connection,
                    wallet: window.solana,
                    uri: metadataUrl,
                    maxSupply: 1,
                });

                store.dispatch(
                    updateNftData({
                        id: nftsData[e]._id,
                        nftId: NftMint.mint.toString(),
                        notify: parseInt(e) === nftsData.length - 1,
                    })
                );
            } catch (err) {
                store.dispatch(toggleLoading(false));
                toast.error(err.message);
                break;
            }
        }
        store.dispatch(resetNftsData());
        store.dispatch(listAllNfts());
    } else {
        store.dispatch(toggleLoading(false));
        toast.info("No data found to create NFT");
    }
};
