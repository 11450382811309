import React, { useState } from "react";
import * as Yup from "yup";
import { Formik } from "formik";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Card, CardHeader, Grid, FilledInput, Button, RadioGroup, FormControlLabel, Radio } from "@material-ui/core";
import { ArrowBack } from "@material-ui/icons";
import { toast } from "react-toastify";
import Select from "react-select";

import componentStyles from "assets/theme/views/admin/tables.js";
import CustomIconButton from "components/CustomButton/CustomIconButton";
import CustomTextButton from "components/CustomButton/CustomTextButton";

import { createNewNftType } from "store/actions";

const useStyles = makeStyles(componentStyles);

export default ({ handleBackButton, dispatch }) => {
    const classes = useStyles();
    const [fields, setFields] = useState([]);
    const [totalFields, setTotalFields] = useState([1]);

    const handleFields = (index, key, value) => {
        let copyArr = fields;
        if (copyArr[index]) {
            copyArr[index] = { ...copyArr[index], [key]: value };
        } else {
            copyArr[index] = { [key]: value };
        }
        setFields(copyArr);
    };

    const validateFields = () => {
        let status = true;
        if (!fields.length) {
            toast.info("Fields cannot be empty");
            status = false;
            return status;
        }
        for (let e in fields) {
            if (fields[e]) {
                if (!fields[e].name) {
                    toast.error(`error in name of field no: ${parseInt(e) + 1}`);
                    status = false;
                    break;
                }
                if (!fields[e].type) {
                    toast.error(`error in type of field no: ${parseInt(e) + 1}`);
                    status = false;
                    break;
                }
            }
        }
        return status;
    };

    const formatFields = () => {
        return fields.map((e, i) => {
            e.name = e.name.toLowerCase().replaceAll(" ", "_");
            e.type = e.type.toLowerCase().replaceAll(" ", "_");
            if (i === 0) {
                e.is_nft_name = true;
            }
            return e;
        });
    };

    return (
        <>
            <Grid container justify="flex-start" spacing={3}>
                <Grid item style={{ width: "100%" }}>
                    <Card style={{ backgroundColor: "grey" }}>
                        <Grid container justify="space-between" spacing={3}>
                            <Grid item style={{ padding: "1.5em" }}>
                                <CustomIconButton title="Back" icon={<ArrowBack />} onClick={() => handleBackButton()} />
                            </Grid>
                        </Grid>
                    </Card>
                </Grid>
            </Grid>
            <br />
            <Card classes={{ root: classes.cardRoot }} style={{ backgroundColor: "#c4c4c4" }}>
                <CardHeader
                    className={classes.cardHeader}
                    title="Add New NFT Type"
                    titleTypographyProps={{
                        component: Box,
                        marginBottom: "0!important",
                        variant: "h3",
                    }}
                />
                <div>
                    <Formik
                        initialValues={{
                            name: "",
                            description: "",
                            qrBased: "0",
                        }}
                        validationSchema={Yup.object().shape({
                            name: Yup.string().max(255).required("Name is required"),
                        })}
                        onSubmit={(values) => {
                            if (validateFields()) {
                                values.fields = formatFields();
                                values.qrBased = parseInt(values.qrBased) ? true : false;
                                dispatch(createNewNftType(values));
                                handleBackButton();
                            }
                        }}
                    >
                        {({ handleBlur, handleChange, handleSubmit, errors, values, touched }) => (
                            <form onSubmit={handleSubmit}>
                                <div style={{ dispaly: "flex", justifyContent: "center", padding: "2em" }}>
                                    <label htmlFor="name" style={{ textTransform: "uppercase" }}>
                                        Name
                                    </label>
                                    <FilledInput
                                        key="name"
                                        name="name"
                                        autoComplete="off"
                                        type="text"
                                        onBlur={handleBlur}
                                        placeholder="Enter name for new type"
                                        onChange={handleChange}
                                        value={values.name}
                                        error={Boolean(touched.name && errors.name)}
                                    />
                                    <div style={{ display: "block", marginLeft: "10px", color: "red", fontSize: 13 }}>
                                        {errors.name}
                                    </div>
                                    <br />
                                    <label htmlFor="description" style={{ textTransform: "uppercase" }}>
                                        Description
                                    </label>
                                    <FilledInput
                                        key="description"
                                        name="description"
                                        autoComplete="off"
                                        type="text"
                                        onBlur={handleBlur}
                                        placeholder="Enter description for new type"
                                        onChange={handleChange}
                                        value={values.description}
                                        error={Boolean(touched.description && errors.description)}
                                    />
                                    <br />

                                    <label htmlFor="description" style={{ textTransform: "uppercase" }}>
                                        Need to generate QR code?
                                    </label>
                                    <RadioGroup
                                        row
                                        key="qrBased"
                                        name="qrBased"
                                        value={values.qrBased}
                                        onChange={handleChange}
                                    >
                                        <FormControlLabel value={"1"} control={<Radio />} label="Yes" />
                                        <FormControlLabel value={"0"} control={<Radio />} label="No" />
                                    </RadioGroup>

                                    <label htmlFor="fields" style={{ textTransform: "uppercase" }}>
                                        Fields
                                    </label>
                                    <div style={{ dispaly: "flex", justifyContent: "center", padding: "2em" }}>
                                        {totalFields.map((e, i) => {
                                            return (
                                                <div key={i}>
                                                    <label htmlFor={`fieldname${i}`} style={{ textTransform: "uppercase" }}>
                                                        Field Name
                                                    </label>
                                                    <FilledInput
                                                        name={`name${i}`}
                                                        autoComplete="off"
                                                        type="text"
                                                        placeholder="Enter field name"
                                                        onChange={(e) => handleFields(i, "name", e.target.value)}
                                                    />
                                                    <br />

                                                    <label htmlFor={`fieldtype${i}`} style={{ textTransform: "uppercase" }}>
                                                        Field Type
                                                    </label>
                                                    <Select
                                                        options={[
                                                            { label: "Text", value: "text" },
                                                            { label: "Number", value: "number" },
                                                            { label: "Date", value: "date" },
                                                            { label: "Time", value: "time" },
                                                            { label: "Date And Time", value: "datetime-local" },
                                                        ]}
                                                        onChange={(e) => handleFields(i, "type", e.value)}
                                                        placeholder="Select field type"
                                                    />
                                                    <br />
                                                </div>
                                            );
                                        })}
                                        <CustomTextButton
                                            size="small"
                                            title="new field"
                                            color="primary"
                                            // disabled={!fields.length}
                                            onClick={() => setTotalFields([...totalFields, totalFields.pop() + 1])}
                                        />
                                    </div>
                                    <Grid container justify="center">
                                        <Grid item>
                                            <Button
                                                color="primary"
                                                // onClick={components.handleSubmit}
                                                type="submit"
                                                variant="contained"
                                                disabled={values.name === ""}
                                            >
                                                Submit
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </div>
                            </form>
                        )}
                    </Formik>
                </div>
            </Card>
        </>
    );
};
