import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { Container, Box, Grid, Card } from "@material-ui/core";
import { Add } from "@material-ui/icons";
// core components
import CustomHeader from "components/Headers/CustomHeader";
import componentStyles from "assets/theme/views/admin/tables.js";
import CustomTable from "components/CustomTable/CustomTable";
import CustomIconButton from "components/CustomButton/CustomIconButton";
import CustomTextButton from "components/CustomButton/CustomTextButton";
import CustomSwitch from "components/CustomSwitch";
import AddNewType from "./AddNewType";

import { listAllNftTypes, resetNftTypesData, toggleNftTypeVisibility } from "store/actions";
import { history } from "utils";

const useStyles = makeStyles(componentStyles);

function NftTypes() {
    const classes = useStyles();
    const dispatch = useDispatch();

    const [isAddNew, setIsAddNew] = useState(false);

    const { nftTypesData, showViewMore } = useSelector(({ nftTypes: { list, showViewMore } }) => ({
        nftTypesData: list,
        showViewMore,
    }));

    useEffect(() => {
        dispatch(resetNftTypesData());
        fetchMoreData();
    }, []);

    const fetchMoreData = () => {
        dispatch(listAllNftTypes());
    };

    const handleAddNewPage = () => {
        setIsAddNew(!isAddNew);
    };

    const handleToggleChange = (data) => {
        dispatch(toggleNftTypeVisibility({ id: data?._id }));
    };

    return (
        <>
            <CustomHeader />
            <Container maxWidth={false} component={Box} marginTop="-6rem" classes={{ root: classes.containerRoot }}>
                {!isAddNew ? (
                    <>
                        <Grid container justify="flex-start" spacing={3}>
                            <Grid item style={{ width: "100%" }}>
                                <Card style={{ backgroundColor: "grey" }}>
                                    <Grid container justify="space-between" spacing={3}>
                                        <Grid item style={{ padding: "1.5em" }}>
                                            <CustomIconButton
                                                title="Add New Type"
                                                icon={<Add />}
                                                onClick={() => handleAddNewPage()}
                                            />
                                        </Grid>
                                    </Grid>
                                </Card>
                            </Grid>
                        </Grid>
                        <br />
                        <CustomTable
                            title="NFT Types"
                            columns={[
                                {
                                    title: "Actions",
                                    render: (rowData) => (
                                        <CustomTextButton
                                            color="primary"
                                            variant="outlined"
                                            title="open"
                                            size="small"
                                            onClick={() => history.push(`/app/type/${rowData?._id}`)}
                                        />
                                    ),
                                },
                                {
                                    title: "Visibility",
                                    render: (rowData) => (
                                        <CustomSwitch
                                            checked={rowData?.is_visible}
                                            onChange={() => handleToggleChange(rowData)}
                                        />
                                    ),
                                },
                                { title: "Name", field: "name" },
                                { title: "Description", field: "description" },
                            ]}
                            data={nftTypesData}
                            showViewMoreButton={showViewMore}
                            viewMoreButtonClick={fetchMoreData}
                        />
                    </>
                ) : (
                    <AddNewType handleBackButton={handleAddNewPage} dispatch={dispatch} />
                )}
            </Container>
        </>
    );
}

export default NftTypes;
