import { Grain, Image, VpnKey, AccountBalanceWallet, FormatListBulleted } from "@material-ui/icons";

import Login from "views/auth/Login.js";
import Creators from "views/admin/creators";
import NftsList from "views/admin/nfts";
import Wallets from "views/admin/wallets";
import NftTypes from "views/admin/nft-types";
import TypeDetails from "views/admin/nft-types/TypeDetails";

var adminRoutes = [
    {
        path: "/dashboard",
        name: "Creators",
        icon: Grain,
        iconColor: "Primary",
        component: Creators,
        layout: "/app",
        visibility: true,
    },
    {
        path: "/arts",
        name: "NFT Arts",
        icon: Image,
        iconColor: "Primary",
        component: NftsList,
        layout: "/app",
        visibility: true,
    },
    {
        path: "/nft-types",
        name: "NFT Types",
        icon: FormatListBulleted,
        iconColor: "Primary",
        component: NftTypes,
        layout: "/app",
        visibility: true,
    },
    {
        path: "/wallets",
        name: "Wallets",
        icon: AccountBalanceWallet,
        iconColor: "Primary",
        component: Wallets,
        layout: "/app",
        visibility: true,
    },
    {
        path: "/type/:id",
        name: "Type Details",
        icon: FormatListBulleted,
        iconColor: "Primary",
        component: TypeDetails,
        layout: "/app",
        visibility: false,
    },
    {
        divider: true,
        layout: "/app",
        visibility: true,
    },
];

var authRoutes = [
    {
        path: "/login",
        name: "Login",
        icon: VpnKey,
        iconColor: "Info",
        component: Login,
        layout: "/auth",
    },
];
export { adminRoutes, authRoutes };
