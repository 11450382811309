import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Card, CardHeader, Grid, FilledInput, Button } from "@material-ui/core";
import { ArrowBack } from "@material-ui/icons";
import { toast } from "react-toastify";
import Select from "react-select";

import componentStyles from "assets/theme/views/admin/tables.js";
import CustomIconButton from "components/CustomButton/CustomIconButton";
import CustomTextButton from "components/CustomButton/CustomTextButton";

import { updateNftType } from "store/actions";
import { history } from "utils";

const useStyles = makeStyles(componentStyles);

export default ({ handleBackButton, dispatch, typeId }) => {
    const classes = useStyles();
    const [fields, setFields] = useState([]);
    const [totalFields, setTotalFields] = useState([1]);

    const handleFields = (index, key, value) => {
        let copyArr = fields;
        if (copyArr[index]) {
            copyArr[index] = { ...copyArr[index], [key]: value };
        } else {
            copyArr[index] = { [key]: value };
        }
        setFields(copyArr);
    };

    const validateFields = () => {
        let status = true;
        if (!fields.length) {
            toast.info("Fields cannot be empty");
            status = false;
            return status;
        }
        for (let e in fields) {
            if (fields[e]) {
                if (!fields[e].name) {
                    toast.error(`error in name of field no: ${parseInt(e) + 1}`);
                    status = false;
                    break;
                }
                if (!fields[e].type) {
                    toast.error(`error in type of field no: ${parseInt(e) + 1}`);
                    status = false;
                    break;
                }
            }
        }
        return status;
    };

    const formatFields = () => {
        return fields.map((e) => {
            e.name = e.name.toLowerCase().replaceAll(" ", "_");
            e.type = e.type.toLowerCase().replaceAll(" ", "_");
            return e;
        });
    };

    const handleSubmit = () => {
        if (validateFields()) {
            let values = {};
            values.fields = formatFields();
            values.id = typeId;
            dispatch(updateNftType(values));
            history.push("/app/nft-types");
        } else {
            handleBackButton();
        }
    };

    return (
        <>
            <Grid container justify="flex-start" spacing={3}>
                <Grid item style={{ width: "100%" }}>
                    <Card style={{ backgroundColor: "grey" }}>
                        <Grid container justify="space-between" spacing={3}>
                            <Grid item style={{ padding: "1.5em" }}>
                                <CustomIconButton title="Back" icon={<ArrowBack />} onClick={() => handleBackButton()} />
                            </Grid>
                        </Grid>
                    </Card>
                </Grid>
            </Grid>
            <br />
            <Card classes={{ root: classes.cardRoot }} style={{ backgroundColor: "#c4c4c4" }}>
                <CardHeader
                    className={classes.cardHeader}
                    title="Add New Fields"
                    titleTypographyProps={{
                        component: Box,
                        marginBottom: "0!important",
                        variant: "h3",
                    }}
                />
                <div>
                    <form onSubmit={handleSubmit}>
                        <div style={{ dispaly: "flex", justifyContent: "center", padding: "2em" }}>
                            <div style={{ dispaly: "flex", justifyContent: "center", padding: "2em" }}>
                                {totalFields.map((e, i) => {
                                    return (
                                        <div key={i}>
                                            <label htmlFor={`fieldname${i}`} style={{ textTransform: "uppercase" }}>
                                                Field Name
                                            </label>
                                            <FilledInput
                                                name={`name${i}`}
                                                autoComplete="off"
                                                type="text"
                                                placeholder="Enter field name"
                                                onChange={(e) => handleFields(i, "name", e.target.value)}
                                            />
                                            <br />

                                            <label htmlFor={`fieldtype${i}`} style={{ textTransform: "uppercase" }}>
                                                Field Type
                                            </label>
                                            <Select
                                                options={[
                                                    { label: "Text", value: "text" },
                                                    { label: "Number", value: "number" },
                                                    { label: "Date", value: "date" },
                                                    { label: "Time", value: "time" },
                                                    { label: "Date And Time", value: "datetime-local" },
                                                ]}
                                                onChange={(e) => handleFields(i, "type", e.value)}
                                                placeholder="Select field type"
                                            />
                                            <br />
                                        </div>
                                    );
                                })}
                                <CustomTextButton
                                    size="small"
                                    title="new field"
                                    color="primary"
                                    // disabled={!fields.length}
                                    onClick={() => setTotalFields([...totalFields, totalFields.pop() + 1])}
                                />
                            </div>
                            <Grid container justify="center">
                                <Grid item>
                                    <Button color="primary" type="submit" variant="contained">
                                        Submit
                                    </Button>
                                </Grid>
                            </Grid>
                        </div>
                    </form>
                </div>
            </Card>
        </>
    );
};
