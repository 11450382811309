import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { Container, Box, Grid, Card } from "@material-ui/core";
import { Add } from "@material-ui/icons";
// core components
import CustomHeader from "components/Headers/CustomHeader";

import componentStyles from "assets/theme/views/admin/tables.js";
import CustomTable from "components/CustomTable/CustomTable";
import CustomIconButton from "components/CustomButton/CustomIconButton";
import CustomSidebar from "components/CustomSidebar/CustomSidebar";
import CustomModal from "components/CustomModal/CustomModal";
import CustomTextButton from "components/CustomButton/CustomTextButton";
import ModalContent from "./ModalContent";
import { toggleSidebar } from "store/actions";
import { generateInputs, initialValues, validationSchema, handleSingleWalletCreation } from "./CreateSingleWallet";
import { listAllWallets, resetWalletsData, toggleModal, apiCallAuthorized } from "store/actions";
import { FETCH_LEDGER_WALLET_DETAILS } from "graphql";

const useStyles = makeStyles(componentStyles);

function Wallets() {
    const classes = useStyles();
    const dispatch = useDispatch();

    const [walletId, setwalletId] = useState("");
    const [isLedgerBalFetched, setIsLedgerBalFetched] = useState(false);
    const [ledgerBal, setLedgerBal] = useState(0);

    const { walletsData, showViewMore } = useSelector(({ wallets: { list, showViewMore } }) => ({
        walletsData: list,
        showViewMore,
    }));

    useEffect(() => {
        dispatch(resetWalletsData());
        fetchMoreData();
        if (!isLedgerBalFetched) {
            fetchLedgerBalance();
        }
    }, []);

    const fetchMoreData = () => {
        dispatch(listAllWallets());
    };

    const fetchLedgerBalance = async () => {
        let response = await apiCallAuthorized(FETCH_LEDGER_WALLET_DETAILS);

        if (response.status) {
            const {
                list_ledger_details: { wallet_balance },
            } = response.data;
            setLedgerBal(wallet_balance);
            return;
        }
        return;
    };

    return (
        <>
            <CustomHeader />
            <Container maxWidth={false} component={Box} marginTop="-6rem" classes={{ root: classes.containerRoot }}>
                <Grid container justify="flex-start" spacing={3}>
                    <Grid item style={{ width: "100%" }}>
                        <Card style={{ backgroundColor: "grey" }}>
                            <Grid container justify="space-between" spacing={3}>
                                <Grid item style={{ padding: "1.5em" }}>
                                    <CustomIconButton
                                        title="Add new"
                                        icon={<Add />}
                                        onClick={() => dispatch(toggleSidebar(true))}
                                        style={{ width: "100%" }}
                                    />
                                </Grid>
                                <Grid item style={{ padding: "1.5em" }}>
                                    <CustomTextButton title={`Ledger Balance: ${ledgerBal} SOL`} />
                                </Grid>
                            </Grid>
                        </Card>
                    </Grid>
                </Grid>
                <br />
                <CustomTable
                    title="Wallets"
                    columns={[
                        {
                            title: "Actions",
                            render: (rowData) => (
                                <CustomTextButton
                                    color="primary"
                                    variant="outlined"
                                    onClick={() => {
                                        setwalletId(rowData.wallet_id);
                                        dispatch(toggleModal(true));
                                    }}
                                    title="Private Key"
                                    size="small"
                                />
                            ),
                        },
                        { title: "Wallet Address", field: "wallet_id" },
                        { title: "Linked to", render: (rowData) => <p>{rowData?.user_id?.email}</p> },
                    ]}
                    data={walletsData}
                    showViewMoreButton={showViewMore}
                    viewMoreButtonClick={fetchMoreData}
                />
            </Container>
            <CustomSidebar
                title="Add Wallet"
                inputs={generateInputs()}
                validationSchema={validationSchema}
                initialValues={initialValues}
                handleSubmit={handleSingleWalletCreation}
            />
            <CustomModal content={ModalContent({ walletId: walletId })} />
        </>
    );
}

export default Wallets;
