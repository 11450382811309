import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { Container, Box, Grid, Card, Checkbox } from "@material-ui/core";
// core components
import CustomHeader from "components/Headers/CustomHeader";
import componentStyles from "assets/theme/views/admin/tables.js";
import CustomTable from "components/CustomTable/CustomTable";
import CustomBackButton from "components/CustomButton/CustomBackButton";
import CustomTextButton from "components/CustomButton/CustomTextButton";
import AddNewFields from "./AddNewFields";

import { listNftTypeDetails, updateNftNameField } from "store/actions";

const useStyles = makeStyles(componentStyles);

function TypeDetails() {
    const classes = useStyles();
    const dispatch = useDispatch();
    const { id } = useParams();

    const [isAddNew, setIsAddNew] = useState(false);

    const { typeDetails } = useSelector(({ nftTypes: { details } }) => ({
        typeDetails: details,
    }));

    useEffect(() => {
        dispatch(listNftTypeDetails({ id: id }));
    }, []);

    const handleAddNewPage = () => {
        setIsAddNew(!isAddNew);
    };

    const handleUpdateNameField = (data) => {
        dispatch(updateNftNameField({ typeId: id, fieldId: data._id }));
    };

    return (
        <>
            <CustomHeader />
            <Container maxWidth={false} component={Box} marginTop="-6rem" classes={{ root: classes.containerRoot }}>
                {!isAddNew ? (
                    <>
                        <Grid container>
                            <Grid item style={{ width: "100%" }}>
                                <Card style={{ backgroundColor: "grey" }}>
                                    <Grid container justify="space-between" spacing={3}>
                                        <Grid item style={{ padding: "1.5em" }}>
                                            <CustomBackButton />
                                        </Grid>
                                        <Grid item style={{ padding: "1.5em" }}>
                                            <CustomTextButton title="New Field +" onClick={() => handleAddNewPage()} />
                                        </Grid>
                                    </Grid>
                                </Card>
                            </Grid>
                        </Grid>
                        <br />
                        <Grid container spacing={3}>
                            <Grid item style={{ width: "100%" }}>
                                <Card style={{ backgroundColor: "whitesmoke" }}>
                                    <Grid container justify="center" spacing={1}>
                                        <Grid item style={{ padding: "1em" }}>
                                            <h3 style={{ textTransform: "capitalize" }}>{typeDetails?.name}</h3>
                                        </Grid>
                                    </Grid>
                                    {/* <Grid container justify="center" spacing={1} style={{ paddingTop: "1em" }}>
                                        <Grid item style={{ padding: "1em" }}>
                                            <p style={{ textTransform: "capitalize" }}>{typeDetails?.description}</p>
                                        </Grid>
                                    </Grid> */}
                                </Card>
                            </Grid>
                        </Grid>
                        <br />
                        <CustomTable
                            title="Fields"
                            columns={[
                                {
                                    title: "Name",
                                    field: "name",
                                    render: (rowData) => <>{rowData?.name.replaceAll("_", " ")}</>,
                                },
                                {
                                    title: "Type",
                                    field: "type",
                                    render: (rowData) => (
                                        <>{rowData?.type === "datetime-local" ? "date and time" : rowData?.type}</>
                                    ),
                                },
                                {
                                    title: "Name Field",
                                    render: (rowData) => (
                                        <Checkbox
                                            color="primary"
                                            checked={rowData?.is_nft_name ?? false}
                                            onClick={() => handleUpdateNameField(rowData)}
                                        />
                                    ),
                                },
                                { title: "Min", field: "min" },
                                { title: "Max", field: "max" },
                            ]}
                            data={typeDetails?.fields ?? []}
                        />
                    </>
                ) : (
                    <AddNewFields handleBackButton={handleAddNewPage} dispatch={dispatch} typeId={typeDetails?._id} />
                )}
            </Container>
        </>
    );
}

export default TypeDetails;
