import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { Container, Box, Grid, Card } from "@material-ui/core";
import AsyncSelect from "react-select/async";
import Select, { components } from "react-select";
import { CloudUpload } from "@material-ui/icons";
// core components
import CustomHeader from "components/Headers/CustomHeader";

import componentStyles from "assets/theme/views/admin/tables.js";
import CustomTable from "components/CustomTable/CustomTable";
import CustomIconButton from "components/CustomButton/CustomIconButton";
import CustomSidebar from "components/CustomSidebar/CustomSidebar";
import CustomTextButton from "components/CustomButton/CustomTextButton";
import { listAllNfts, resetNftsData, apiCall } from "store/actions";
import { generateInputs, validationSchema, initialValues, handleSingleNft } from "./CreateSingleNft";
import { handleSingleNftCreation, handleBulkNftCreation } from "./nftCreations";
import { FETCH_CREATORS_FOR_DROPDOWN } from "graphql";

const useStyles = makeStyles(componentStyles);

function NftsList() {
    const classes = useStyles();
    const dispatch = useDispatch();

    const [status, setStatus] = useState([{ label: "Pending", value: "pending" }]);
    const [creator, setCreator] = useState([{ label: "All", value: "all" }]);
    const [options, setOptions] = useState([]);

    const { nftsData, showViewMore } = useSelector(({ nfts: { list, showViewMore } }) => ({
        nftsData: list,
        showViewMore,
    }));

    useEffect(() => {
        dispatch(resetNftsData());
        fetchMoreData();
        fetchCreatorsDropdown();
    }, [status, creator]);

    const fetchMoreData = () => {
        dispatch(listAllNfts({ status: status[0].value, creator: creator[0].value }));
    };

    const fetchCreatorsDropdown = async () => {
        let optionsData = [];
        let apiResponse = await apiCall(FETCH_CREATORS_FOR_DROPDOWN, {
            status: status[0].value,
        });

        if (apiResponse.status) {
            let {
                list_creators_for_dropdown: { data },
            } = apiResponse.data;

            optionsData = data.map((e, i) => {
                return {
                    value: e?._id,
                    label: e?.wallet_id?.wallet_id,
                    optionLabel: (
                        <div>
                            <p
                                style={{
                                    fontSize: 13,
                                    fontFamily: "inherit",
                                    fontWeight: "bold",
                                }}
                            >
                                {e?.name}
                            </p>
                            <p
                                style={{
                                    fontSize: 13,
                                    fontFamily: "inherit",
                                    fontWeight: "bold",
                                }}
                            >
                                {e?.wallet_id?.wallet_id}
                            </p>
                        </div>
                    ),
                };
            });
            setOptions(optionsData);
        }
        return optionsData;
    };

    return (
        <>
            <CustomHeader />
            <Container maxWidth={false} component={Box} marginTop="-6rem" classes={{ root: classes.containerRoot }}>
                <Grid container justify="flex-start" spacing={3}>
                    <Grid item style={{ width: "100%" }}>
                        <Card style={{ backgroundColor: "grey" }}>
                            <Grid container justify="space-between" spacing={3}>
                                <Grid item style={{ padding: "1.5em" }}>
                                    <CustomIconButton
                                        title="Create all NFTs"
                                        icon={<CloudUpload />}
                                        onClick={() =>
                                            handleBulkNftCreation({ data: nftsData, walletId: creator[0].label })
                                        }
                                        otherProps={{ id: "bulk-upld-btn" }}
                                        // style={{ width: "100%" }}
                                    />
                                </Grid>
                                <Grid item style={{ padding: "1.5em" }}>
                                    <div style={{ padding: "5px" }}>
                                        <Select
                                            defaultValue={creator}
                                            onChange={(e) => setCreator([e])}
                                            defaultOptions
                                            styles={{
                                                multiValueLabel: (base) => ({
                                                    ...base,
                                                    width: 200,
                                                    maxWidth: 200,
                                                    minWidth: 150,
                                                }),
                                                control: (base) => ({
                                                    ...base,
                                                    height: 40,
                                                    maxHeight: 40,
                                                    maxWidth: 200,
                                                    minWidth: 150,
                                                }),
                                                valueContainer: (base) => ({
                                                    ...base,
                                                    height: 30,
                                                    maxWidth: 200,
                                                    minWidth: 150,
                                                }),
                                            }}
                                            options={options}
                                            components={{
                                                ValueContainer: ({ children, getValue, ...props }) => {
                                                    let maxToShow = 2;
                                                    var length = getValue().length;
                                                    let displayChips = React.Children.toArray(children).slice(0, maxToShow);
                                                    let shouldBadgeShow = length > maxToShow;
                                                    let displayLength = length - maxToShow;
                                                    return (
                                                        <components.ValueContainer {...props}>
                                                            {!props.selectProps.inputValue && displayChips}
                                                            <div style={{ fontSize: 12 }}>
                                                                {shouldBadgeShow && `+ ${displayLength}`}
                                                            </div>
                                                        </components.ValueContainer>
                                                    );
                                                },
                                                Option: ({ children, ...props }) => {
                                                    return (
                                                        <components.Option {...props}>
                                                            <div>{props.data.optionLabel}</div>
                                                        </components.Option>
                                                    );
                                                },
                                            }}
                                        />
                                    </div>
                                    <div style={{ padding: "5px" }}>
                                        <Select
                                            defaultValue={status}
                                            options={[
                                                { label: "All", value: "all" },
                                                { label: "Pending", value: "pending" },
                                                { label: "Created", value: "created" },
                                                { label: "Sold", value: "sold" },
                                            ]}
                                            onChange={(e) => setStatus([e])}
                                        />
                                    </div>
                                </Grid>
                            </Grid>
                        </Card>
                    </Grid>
                </Grid>
                <br />
                <CustomTable
                    title="NFT Arts"
                    columns={[
                        // {
                        //     title: "Actions",
                        //     render: (rowData) => (
                        //         <CustomTextButton
                        //             color="primary"
                        //             variant="outlined"
                        //             onClick={() => handleSingleNftCreation(rowData)}
                        //             title="Create NFT"
                        //             size="small"
                        //             disabled={rowData?.nft_id !== null}
                        //         />
                        //     ),
                        // },
                        { title: "Name", field: "name" },
                        {
                            title: "Artwork",
                            field: "art_url",
                            render: (rowData) => <img src={rowData?.art_url} width={40} height={40} />,
                        },
                        { title: "Nft ID", field: "nft_id" },
                        { title: "Status", field: "status" },
                        {
                            title: "Creator",
                            field: "creator",
                            render: (rowData) => <p>{rowData?.creator_id?.name}</p>,
                        },
                    ]}
                    data={nftsData}
                    showViewMoreButton={showViewMore}
                    viewMoreButtonClick={fetchMoreData}
                    emptyText={status === "pending" ? "No pending artworks found" : "No artworks found"}
                />
            </Container>
            <CustomSidebar
                title="New Art Work"
                inputs={generateInputs()}
                validationSchema={validationSchema}
                initialValues={initialValues}
                handleSubmit={handleSingleNft}
            />
        </>
    );
}

export default NftsList;
