import { unAuthorizedPost } from "services";
import { history } from "utils";
import { LOGIN_SUCCESS, LOGOUT, TOGGLE_LOADING, TOGGLE_SIDEBAR } from "../types";
import { ADMIN_LOGIN } from "graphql";

export const loginUser = (requestData) => {
    return async (dispatch) => {
        dispatch({ type: TOGGLE_LOADING, payload: true });
        dispatch({ type: TOGGLE_SIDEBAR, payload: false });

        let apiResponse = await unAuthorizedPost("", {
            query: ADMIN_LOGIN,
            variables: requestData,
        });

        if (apiResponse.data.customStatus) {
            let {
                admin_login: { token },
            } = apiResponse.data.data;

            dispatch({ type: LOGIN_SUCCESS, payload: token });
            history.push("/app/dashboard");
        }

        dispatch({ type: TOGGLE_LOADING, payload: false });
    };
};

export const logoutUser = () => {
    return (dispatch) => {
        dispatch({ type: LOGOUT });
    };
};
