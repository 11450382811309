export const FETCH_ALL_NFT_TYPES = `query ($pageSize: Int, $pageNumber: Int) {
  list_all_nft_types(page_size: $pageSize, page_number: $pageNumber) {
    message
    data {
      _id
      name
      description
      qr_based
      is_visible
    }
    hasMore
  }
}

  `;

export const CREATE_NEW_NFT_TYPE = `mutation ($name: String!, $description: String, $qrBased: Boolean, $fields: [add_new_nft_type_fields]!) {
  add_new_nft_type(name: $name, description: $description, qr_based: $qrBased, fields: $fields) {
    message
  }
}

`;

export const FETCH_NFT_TYPE_DETAILS = `query ($id: String!) {
  list_nft_type_details(_id: $id) {
    message
    data {
      _id
      name
      description
      qr_based
      is_visible
      fields {
        _id
        name
        type
        min
        max
        is_visible
        is_nft_name
      }
    }
  }
}
`;

export const TOGGLE_TYPE_VISIBILITY = `mutation ($id: String!) {
  update_nft_type_visibility(_id: $id) {
    message
  }
}
`;

export const UPDATE_NFT_TYPES = `mutation ($id: String!, $fields: [update_nft_type_fields]!) {
  update_nft_type(_id: $id, fields: $fields) {
    message
  }
}
`;

export const UPDATE_NFT_NAME_FIELD = `mutation ($typeId: String!, $fieldId: String!) {
  update_nft_name_field(type_id: $typeId, field_id: $fieldId) {
    message
  }
}
`;
